import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from './affichainlogo.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
      <Link to="/">
          <img src={logo} alt="Affichain Logo" />
        </Link>
      </div>
      <div className="site-name">Affi-Chain</div>
      <div className={`nav ${menuOpen ? 'open' : ''}`}>
        <Link to="/solutions">Solutions</Link>
        <Link to="/resources">Resources</Link>
        <Link to="/partners">Partners</Link>
        <Link to="/contact">Contact Us</Link> {/* Updated to match the route */}
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/about">About</Link>
        <Link to="/blogs">Blogs</Link>
      </div>
      <div className="login-signup">
        <Link to="/signup">
          <button className="login-signup-button">Sign In</button>
        </Link>
      </div>
      <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </header>
  );
};

export default Header;
import React from 'react';
import './AboutUs.css'; // Import your CSS file for styling

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h1 className="about-us-heading">About Us</h1>
      <div className="about-us-content">
        <p>Welcome to AffiChain, your trusted partner in the digital world. At AffiChain, we specialize in providing innovative and comprehensive solutions tailored to meet the diverse needs of modern businesses. Our mission is to empower businesses with cutting-edge technology and expert services, ensuring growth and success in an increasingly competitive landscape.</p>

        <h2>Who We Are</h2>
        <p>AffiChain is a service and product-based company dedicated to delivering top-notch digital solutions. Our team comprises seasoned professionals with extensive experience in various domains, including digital marketing, web and app development, AI/ML, and more.</p>

        <h2>What We Do</h2>
        <ul>
          <li>
            <strong>Digital Marketing:</strong> We offer comprehensive digital marketing services, including PPC from Google, SEO, social media marketing, and more, to boost your online presence and drive traffic to your website.
          </li>
          <li>
            <strong>Web Development:</strong> Our expert developers create responsive, user-friendly websites tailored to your business needs, ensuring a seamless user experience.
          </li>
          <li>
            <strong>Android/iOS Development:</strong> We design and develop high-performance mobile applications for both Android and iOS platforms, enhancing your reach to mobile users.
          </li>
          <li>
            <strong>URL Shortening Service:</strong> Our URL shortening service not only makes your links more manageable but also provides opportunities to earn money through our affiliate program.
          </li>
          <li>
            <strong>Sentiment Analysis:</strong> We provide advanced sentiment analysis solutions to help businesses understand customer opinions and emotions by analyzing text data from various sources.
          </li>
          <li>
            <strong>Text Generation AI:</strong> Our AI-powered text generation services create coherent and contextually appropriate content, perfect for customer support, content creation, and more.
          </li>
          <li>
            <strong>IPO Bidding System:</strong> We offer a robust IPO bidding system that streamlines the bidding process for Initial Public Offerings, ensuring efficiency and transparency.
          </li>
          <li>
            <strong>SIM Management System:</strong> Our SIM management solutions help telecom operators, enterprises, and retailers manage SIM card inventories, activations, and deactivations with ease.
          </li>
          <li>
            <strong>ResellerSIM Pro:</strong> This solution facilitates the management and distribution of eSIMs for resellers and salesmen, ensuring efficient operations and customer satisfaction.
          </li>
        </ul>

        <h2>Why Choose Us</h2>
        <p>At AffiChain, we are committed to excellence and innovation. We prioritize your needs and work closely with you to deliver personalized solutions that drive results. Our dedication to quality, reliability, and customer satisfaction sets us apart as a trusted partner in your business journey.</p>

        <h2>Our Vision</h2>
        <p>To become the leading provider of URL shortening services and digital solutions, enabling individuals and businesses to earn money online effortlessly. We aim to revolutionize the way users interact with digital content by offering innovative tools that simplify and enhance their online experience.</p>

        <h2>Our Mission</h2>
        <p>To empower users by providing cutting-edge URL shortening services that open new avenues for earning money online. Our mission is to deliver reliable and efficient digital solutions, including digital marketing, web development, and mobile app development, to help our clients achieve their business goals. We are dedicated to fostering a seamless and rewarding online environment where every click can turn into an earning opportunity.</p>
      </div>
    </div>
  );
};

export default AboutUs;

// UploadBlogs.js
import React from 'react';

const UploadBlogs = () => {
  return (
    <div>
      <h1>Upload Blogs Here</h1>
      {/* Your upload form or content */}
    </div>
  );
};

export default UploadBlogs;

import React from 'react';
import './Resources.css';
import resourceImage from './productimg.png';

const Resources = () => {
  return (
    <div className="resources-container">
      <div className="left-side">
        <img src={resourceImage} alt="Resources" className="resource-image" />
      </div>
      <div className="right-side">
        <p className="resource-description">
          At <span className="highlight">AffiChain</span>, we offer a diverse range of best solutions to meet your business needs. Our products include Sentiment Analysis, Text Generation AI, IPO Bidding Systems, and SIM Management. Additionally, we are best in Digital Marketing, PPC from Google, Website Development, and Android/iOS Development. Partner with <span className="highlight">AffiChain</span> to leverage innovative technology and expert services for your business growth.
        </p>
      </div>
      <hr className="divider" />
    </div>
  );
};

export default Resources;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Blog.css'; // Assuming you have a separate CSS file for styling

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState('Latest URL');

  const categories = [
    'Latest URL',
    'Short URL',
    'Affiliate Marketing',
    'Earn Money Online',
    'Digital Marketing',
    'Finance',
  ];

  return (
    <div className="blog-container">
      <div className="categories">
        {categories.map((category) => (
          <span
            key={category}
            className={`category-item ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </span>
        ))}
      </div>
      {/* Here you can add the content for the selected category */}
    </div>
  );
};

export default Blog;

// Signup.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from './firebase';  // Ensure db is imported here
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const Signup = () => {
  const navigate = useNavigate();

  const handleGoogleSignup = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      console.log("Signed in user:", user);

      const userRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(userRef);

      if (!docSnap.exists()) {
        await setDoc(userRef, { email: user.email });
        console.log("New user, redirecting to user details...");
        navigate('/userdetails');
      } else {
        const userData = docSnap.data();
        if (!userData.name) {
          console.log("User exists without name, redirecting to user details...");
          navigate('/userdetails');
        } else {
          console.log("User exists with name, redirecting to home...");
          navigate('/home');
        }
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  return (
    <div className="max-w-sm mx-auto p-5 border rounded-md bg-white shadow-md text-center">
      <h1 className="text-2xl font-semibold mb-3">Welcome to AffiChain!!!</h1>
      <p className="mb-4">Login/Signup using Gmail</p>
      <button 
        onClick={handleGoogleSignup} 
        className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700"
      >
        Sign up with Google
      </button>
    </div>
  );
};

export default Signup;

// UserDetails.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from './firebase';  // Ensure db is imported here
import { doc, setDoc } from 'firebase/firestore';

const UserDetails = () => {
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const user = auth.currentUser;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        await setDoc(userRef, { name, email: user.email }, { merge: true });
        navigate('/home');
      }
    } catch (error) {
      console.error("Error storing user details:", error);
    }
  };

  return (
    <div className="max-w-sm mx-auto p-5 border rounded-md bg-white shadow-md text-center">
      <h1 className="text-2xl font-semibold mb-5">Welcome {user.email}</h1>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="mb-4">
          <label className="block text-left mb-2">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="border p-2 w-full rounded"
          />
        </div>
        <button type="submit" className="bg-blue-500 text-white py-2 rounded hover:bg-blue-700">
          Submit
        </button>
      </form>
    </div>
  );
};

export default UserDetails;

import React, { useState } from 'react';
import './Contact.css';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    description: ''
  });
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmitted(false);
    
    emailjs.send('service_rcypsmk', 'template_9yv8rah', formData, 'wb_LLJFrtN74ILesf')
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        setFormData({ name: '', email: '', phone: '', description: '' }); // Reset form
        setSubmitted(true);
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="contact-page">
      <div className="contact-banner">
        <p className="contact-banner-text">
          Reach out to OmniFronthub for expert solutions and support. We're here to help you.
        </p>
      </div>
      <form onSubmit={handleSubmit} className="contact-form">
        <h2>Fill the form and our team will get in touch with you soon.</h2>
        <label>
          Your Name
          <input 
            type="text" 
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            required 
          />
        </label>
        <label>
          Your Email
          <input 
            type="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
          />
        </label>
        <label>
          Phone Number
          <input 
            type="tel" 
            name="phone" 
            value={formData.phone} 
            onChange={handleChange} 
            required 
          />
        </label>
        <label>
          Your Message
          <textarea 
            name="description" 
            value={formData.description} 
            onChange={handleChange} 
            required 
          />
        </label>
        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        {submitted && <p className="submission-message">Your message has been sent!</p>}
      </form>
    </div>
  );
};

export default Contact;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Solutions.css';
import solutionsImage from './solutionsImage.png';
import service1Image from './dm.png';
import service2Image from './wd.png';
import service3Image from './ia.png';
import service4Image from './aiml.png';
import service5Image from './seo.png';
import service6Image from './networking.png';
import service7Image from './chatbots.png';
import service8Image from './data_analysis.png';

const Solutions = () => {
  const services = [
    {
      name: "Digital Marketing",
      description: "Boost your online presence with our tailored digital marketing strategies.",
      image: service1Image,
    },
    {
      name: "Web Development",
      description: "Create stunning websites that captivate and engage your audience.",
      image: service2Image,
    },
    {
      name: "Mobile Development",
      description: "Develop user-friendly mobile applications for Android and iOS.",
      image: service3Image,
    },
    {
      name: "AI/ML Solutions",
      description: "Harness the power of AI and machine learning to innovate your business.",
      image: service4Image,
    },
    {
      name: "SEO Services",
      description: "Improve your website's visibility and drive organic traffic.",
      image: service5Image,
    },
    {
      name: "Networking Solutions",
      description: "Reliable networking solutions to keep your business connected.",
      image: service6Image,
    },
    {
      name: "Chatbot Development",
      description: "Enhance customer engagement with intelligent chatbots.",
      image: service7Image,
    },
    {
      name: "Data Analysis",
      description: "Transform your data into actionable insights.",
      image: service8Image,
    },
  ];

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    if (cards.length) {
      cards.forEach((card, index) => {
        if (index % 2 === 0) {
          card.classList.add('animate-from-left');
        } else {
          card.classList.add('animate-from-right');
        }
      });
      if (cards[1]) cards[1].classList.add('animate-from-up');
      if (cards[2]) cards[2].classList.add('animate-from-up');
      if (cards[3]) cards[3].classList.add('animate-from-down');
      if (cards[4]) cards[4].classList.add('animate-from-down');
    }
  }, []);

  return (
    <main className="main-content">
      <div className="flex-container">
        <div className="text-content">
          <p className="heading">
            Build your dream team with <span className="affichain-highlight">Affi-Chain</span>
          </p>
          <p className="description-text">
            Build your dream team with AffiChain! We provide comprehensive solutions tailored to your business needs, including digital marketing, Android/iOS development, web development, server setup, networking, chatbots, and AI/ML. Our expert team is dedicated to delivering high-quality services to help your business thrive in the digital age. Partner with AffiChain and let us bring your vision to life with innovative, reliable, and efficient solutions.
          </p>
          <Link to="/contact" className="contact-button">Contact Us</Link>
        </div>
        <div className="image-container">
          <img src={solutionsImage} alt="Solutions" className="solutions-image" />
        </div>
      </div>
      <hr className="divider" />

      <div className="cards-container">
        {services.map((service, index) => (
          <div key={index} className="card">
            <h3>{service.name}</h3>
            <img src={service.image} alt={service.name} className="card-image" />
            <p className="card-description">{service.description}</p>
          </div>
        ))}
      </div>

      <hr className="divider" />
      <h2 className="why-choose-us">Why Choose Us?</h2>
      <hr className="divider" />
      <p className="why-choose-description">
        At AffiChain, we are committed to excellence and innovation. Our team of seasoned professionals delivers top-notch solutions across various domains, including digital marketing, Android/iOS development, web development, server setup, networking, chatbots, and AI/ML. We pride ourselves on our customer-centric approach, ensuring personalized service that meets your unique needs. With AffiChain, you gain a partner dedicated to your success, offering cutting-edge technology, reliable support, and proven expertise to help your business achieve its goals. Choose us for a seamless, efficient, and results-driven experience.
      </p>

      <div className="thank-you-divider">
        <span className="thank-you-text">Thank You</span>
      </div>
      <hr className="divider" />
    </main>
  );
};

export default Solutions;

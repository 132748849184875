import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBkWxuR2B1r1Qs5ct3d9jq2_rLUM1hJi1U",
  authDomain: "affichain-website.firebaseapp.com",
  projectId: "affichain-website",
  storageBucket: "affichain-website.appspot.com",
  messagingSenderId: "791024550657",
  appId: "1:791024550657:web:cd064a2bdd1eb3eb9d9100",
  measurementId: "G-KFHH11S8LQ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };